import React from "react";
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

const OldOutlet = () => {
  const [inputValue, setInputValue] = useState({
    customerName: "",
    customerPhoneNumber: "",
    otp: "",
    isUPI: false,
    customerUpiId: "",
    batchcode1: "",
    latitude: 0,
    longitude: 0,
    show: false
  });
  var outletId = "";

  useEffect(() => {
    document.title = "Reedem Offer";
    var url = new URL(document.URL);
    outletId = url.searchParams.get("outletid");
    startTimer(600, document.getElementById("demo"));
    mappOutletDetails(outletId);
    getCurrentLocation();


    // console.log(a);
  }, [])
  const [expire, setExpire] = useState(false);
  const [outletDetails, setOutletDetails] = useState();


  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {

      setInputValue({ ...inputValue, latitude: position.coords.latitude, longitude: position.coords.longitude });
    });
  }


  const onFielsValueChange = (e) => {

    setInputValue({
      ...inputValue,
      [e.target.name]: e.target.value
    })

  }

  const getOtp = async () => {

    if ((inputValue.customerPhoneNumber != '' && inputValue.customerPhoneNumber.length == 10)) {
      swal({
        title: "Please wait.....",
        buttons: false,
      });
      let response = await fetch(`https://api.dailymoo.in/v2/promo/customer?action=send_otp&customerPhoneNumber=${inputValue.customerPhoneNumber}`);
      swal.close();
      if (response.status == 200) {
        setInputValue({ ...inputValue, show: true });
        document.getElementById('customerPhoneNumber').readOnly = true;
      }

    } else {
      swal("Mobile No. Can't Be Blank", "", "error");
    }
  }

  const reedemPromoCode = async () => {

    console.log(inputValue.latitude, inputValue.longitude);
    if ((!/^[a-zA-Z ]*$/.test(inputValue.customerName)) || (inputValue.customerName == '')) {
      swal("Please Enter Your Name(Alphabates Only)", "", "error");
    } else if (inputValue.otp == '' || inputValue.otp.length != 6) {
      swal("Please Enter Valid OTP", "", "error");
    }
    else if (!inputValue.isUPI && (inputValue.customerUpiId == '' || inputValue.customerUpiId.length != 10 || !/^[0-9]+\.?[0-9]*$/.test(inputValue.customerUpiId))) {
      swal("Please Enter Valid Paytm No.", "", "error");

    }
    else if (inputValue.isUPI && (inputValue.customerUpiId == '' || inputValue.customerUpiId.indexOf("@") < 0)) {
      swal("Please Enter Valid UPI Id", "", "error");
    } else if (inputValue.batchcode1 == '') {
      swal("Please Enter Batch Code", "", "error");


    }
    else {

      var isPaytm = true;

      if (inputValue.isUPI) {
        isPaytm = false;
      }
      var elements = document.getElementsByClassName("batchcode");
      var batch = [];
      for (var i = 0, len = elements.length; i < len; i++) {
        batch.push({ "batchcode": elements[i].value });
      }
      var batchCodeWithVal = batch.filter(element => {
        if (Object.values(element) != '') {
          return true;
        }

        return false;
      });
      var sku_id_count = {};

      var sku_id = batchCodeWithVal.map(function (string) { return string.batchcode.substr(4, 2) });
      sku_id.forEach(function (x) { sku_id_count[x] = (sku_id_count[x] || 0) + 1; });

      swal({
        title: "Please wait.....",
        buttons: false,
      });
      let reedemed_data = {
        "customerName": inputValue.customerName,
        "customerPhoneNumber": inputValue.customerPhoneNumber,
        "customerUpiId": inputValue.customerUpiId,
        "productsPurchased": batchCodeWithVal,
        "otp": inputValue.otp,
        "lat": inputValue.latitude,
        "lng": inputValue.longitude,
        "outletId": outletId,
        "isPaytm": isPaytm,
        "sku_id_count": sku_id_count
      };

      // console.log(reedemed_data);
      var reedemResponse = await fetch('https://api.dailymoo.in/v2/promo/promo', {
        method: 'POST',
        body: JSON.stringify(reedemed_data)
      });
      swal.close();
      if (reedemResponse.status == 200) {
        var responseReedeem = await reedemResponse.json();
        swal("Success", responseReedeem.message, "success").then(function () {
          window.location.href = "https://dailymoo.in/"
        });

      } else if (reedemResponse.status == 400) {
        var errorresponseReedeem = await reedemResponse.json();
        swal("Warning!", errorresponseReedeem.error, "error");
      } else {
        swal("Error!", "Something Went Wrong", "error");
      }
    }


  }

  function startTimer(duration, display) {
    var timer = duration,
      minutes, seconds;
    setInterval(function () {
      minutes = parseInt(timer / 60, 10)
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      display.textContent = minutes + ":" + seconds;

      if (--timer < 0) {
        timer = duration;
        setExpire(true);
      }
    }, 1000);
  }





  const mappOutletDetails = async (outletId) => {

    let outletRes = await fetch(`https://api.dailymoo.in/v2/promo/promo?action=verify_retailer&outletId=${outletId}`);

    let outletData = await outletRes.json();
    setOutletDetails(outletData);

  }

  return (
    < >

      {!expire && <> <div className="row scroll" style={{ position: "sticky", top: "0", bottom: "0", zIndex: "9999" }}>
        <div className="col-lg-12" style={{ marginTop: "15px" }}>
          <div className="col-lg-4 ">
          </div>
          <div className="col-lg-4 ">
            <p style={{ fontSize: "18px", fontWeight: "bold" }}>Please Complete This offer Within</p>
            <p id="demo" style={{ color: "red", fontSize: "15px", fontWeight: "bold" }}></p>

          </div>
          <div className="col-lg-4 ">
          </div>
        </div>
      </div>
        <form id="promo_form">
          <div className="row" style={{ marginTop: "10px", backgroundColor: "#5C9D04" }}>
            <div className="col-lg-12 col-xs-12" style={{ marginTop: "10px" }}>
              <div className="col-lg-4 col-xs-4"></div>
              <div className="col-lg-4 col-xs-4"> <p style={{ color: "white" }}><b>Redeem Offer</b></p></div>
              <div className="col-lg-4 col-xs-4"><span style={{ marginRight: "5px" }}>
                <a href='tel:+91 7894409550'><svg width="20px" height="20px" viewBox="0 0 24 24" style={{ fill: "#FFFFFF" }} xmlns="http://www.w3.org/2000/svg">
                  <path d="M6,17 L6,11 L4.5,11 C3.67157288,11 3,11.6715729 3,12.5 L3,15.5 C3,16.3284271 3.67157288,17 4.5,17 L6,17 Z M13.9146471,20 L16.0584816,20 C16.7041272,20 17.2773354,19.5868549 17.4815065,18.9743416 L17.7094306,18.2905694 C17.7432317,18.1891661 17.7850711,18.0921054 17.8340988,18 L17.5,18 C17.2238576,18 17,17.7761424 17,17.5 L17,10.5 C17,10.2238576 17.2238576,10 17.5,10 L18,10 L18,8.98439023 C18,5.67068173 15.3137085,2.98439023 12,2.98439023 C8.6862915,2.98439023 6,5.67068173 6,8.98439023 L6,10 L6.5,10 C6.77614237,10 7,10.2238576 7,10.5 L7,17.5 C7,17.7761424 6.77614237,18 6.5,18 L4.5,18 C3.11928813,18 2,16.8807119 2,15.5 L2,12.5 C2,11.1192881 3.11928813,10 4.5,10 L5,10 L5,8.98439023 C5,5.11839698 8.13400675,1.98439023 12,1.98439023 C15.8659932,1.98439023 19,5.11839698 19,8.98439023 L19,10 L19.5,10 C20.8807119,10 22,11.1192881 22,12.5 L22,15.5 C22,16.8807119 20.8807119,18 19.5,18 C19.1180249,18 18.778905,18.2444238 18.6581139,18.6067972 L18.4301898,19.2905694 C18.0899047,20.3114248 17.1345576,21 16.0584816,21 L13.9146471,21 C13.7087289,21.5825962 13.1531094,22 12.5,22 L11.5,22 C10.6715729,22 10,21.3284271 10,20.5 C10,19.6715729 10.6715729,19 11.5,19 L12.5,19 C13.1531094,19 13.7087289,19.4174038 13.9146471,20 L13.9146471,20 Z M18,11 L18,17 L19.5,17 C20.3284271,17 21,16.3284271 21,15.5 L21,12.5 C21,11.6715729 20.3284271,11 19.5,11 L18,11 Z M11,20.5 C11,20.7761424 11.2238576,21 11.5,21 L12.5,21 C12.7761424,21 13,20.7761424 13,20.5 C13,20.2238576 12.7761424,20 12.5,20 L11.5,20 C11.2238576,20 11,20.2238576 11,20.5 Z" />
                </svg></a>
                <Link to="/"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 50 50" style={{ fill: "#FFFFFF", marginLeft: "20px" }}>
                  <path d="M 25 1.0507812 C 24.7825 1.0507812 24.565859 1.1197656 24.380859 1.2597656 L 1.3808594 19.210938 C 0.95085938 19.550938 0.8709375 20.179141 1.2109375 20.619141 C 1.5509375 21.049141 2.1791406 21.129062 2.6191406 20.789062 L 4 19.710938 L 4 46 C 4 46.55 4.45 47 5 47 L 19 47 L 19 29 L 31 29 L 31 47 L 45 47 C 45.55 47 46 46.55 46 46 L 46 19.710938 L 47.380859 20.789062 C 47.570859 20.929063 47.78 21 48 21 C 48.3 21 48.589063 20.869141 48.789062 20.619141 C 49.129063 20.179141 49.049141 19.550938 48.619141 19.210938 L 25.619141 1.2597656 C 25.434141 1.1197656 25.2175 1.0507812 25 1.0507812 z M 35 5 L 35 6.0507812 L 41 10.730469 L 41 5 L 35 5 z"></path>
                </svg></Link>
              </span></div>
            </div>
          </div>
          {outletDetails && <> <div className="row" style={{ marginTop: "10px", border: "1px solid #5C9D04", borderRadius: "15px", marginLeft: "5px", marginRight: "5px" }}>

            <div className="col-lg-12">
              <table className="table table-striped" >
                <thead>

                </thead>
                <tbody>
                  <tr>
                    <td>Outlet Id <br />(ଆଉଟଲେଟ୍ ଆଇଡି)</td>
                    <td>{outletDetails.outlet_id}</td>
                  </tr>
                  <tr>
                    <td>Shop Name <br />(ଦୋକାନ ନାମ)</td>
                    <td>{outletDetails.shop_name}</td>
                  </tr>
                  <tr>
                    <td>Owner Name <br />(ମାଲିକ ନାମ)</td>
                    <td>{outletDetails.owner_name}</td>
                  </tr>
                  <tr>
                    <td>Phone Number <br />(ଫୋନ୍ ନମ୍ବର)</td>
                    <td>{outletDetails.phone_number}</td>
                  </tr>
                  <tr>
                    <td>
                      {outletDetails.is_paytm == 0 ? "UPI ID" : "Paytm No."}
                    </td>
                    <td>{outletDetails.upi_id}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
            <div className="row" style={{ marginTop: "10px" }}>
              <div className="col-lg-12">
                <input type="text" name="customerName" id="customerName" className="form-control" onChange={onFielsValueChange} placeholder="Enter Your Name (ନାମ)" />

              </div>
            </div>
            <div className="row" style={{ marginTop: "10px" }}>
              <div className="col-lg-12">
                <input type="number" name="customerPhoneNumber" id="customerPhoneNumber" value={inputValue.customerPhoneNumber} className="form-control" onChange={(event) => {
                  if (event.target.value.length == 11) return false;
                  setInputValue({ ...inputValue, customerPhoneNumber: event.target.value });
                }
                } placeholder="Enter Your Mobile No. (ମୋବାଇଲ୍ ନମ୍ବର)" />

              </div>
            </div>
            {!inputValue.show && <div className="row " style={{ marginTop: "18px" }}>
              <div className="col-lg-12">
                <button type="button" style={{ backgroundColor: "#5C9D04", color: "white" }} className="btn" onClick={getOtp} >Send OTP</button>

              </div>
            </div>}
            {inputValue.show && <>
              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-lg-12">
                  <input type="number" name="otp" id="otp" value={inputValue.otp} className="form-control" autoComplete="off" onChange={(event) => {
                    if (event.target.value.length == 7) return false;
                    setInputValue({ ...inputValue, otp: event.target.value });
                  }
                  } placeholder="EnterThe OTP (ଓଟିପି ନମ୍ବର)" />

                </div>
              </div>
              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-lg-12">
                  <div className="col-lg-4">
                    <input type="radio" name="payment_method" className="radio_btn" id="payment_method" onClick={(e) => {
                      if (e.target.value == "Paytm") {
                        setInputValue({
                          ...inputValue, isUPI: false
                        })
                      }

                    }} value="Paytm" defaultChecked />&nbsp;&nbsp;&nbsp;Paytm <p style={{ fontSize: "12px", color: "#5C9D04" }}>(Recommended)</p>
                  </div>
                  <div className="col-lg-2"><input type="radio" onClick={(e) => {
                    if (e.target.value == "UPI") {
                      setInputValue({
                        ...inputValue, isUPI: true
                      })
                    }

                  }} className="radio_btn" name="payment_method" id="payment_method" value="UPI" />&nbsp;&nbsp;&nbsp;UPI</div>

                </div>
              </div>
              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-lg-12">
                  <input type="text" name="customerUpiId" id="customerUpiId" autoComplete="off" onChange={onFielsValueChange} className="form-control" placeholder={inputValue.isUPI == true ? "Enter Your UPI Id" : "Enter Your Paytm Number"} />


                </div>
              </div>
              <fieldset style={{ paddingLeft: "0.75em", paddingRight: "0.75em", paddingTop: "0.75em", paddingButtom: "0.75em", marginTop: "20px", marginBottom: "20px", border: "1px solid #5C9D04", borderRadius: "15px" }}>
                <p>Please Enter your product batch code in the below fields.</p>
                <div className="row " style={{ marginTop: "10px" }}>
                  <div className="col-lg-12">
                    <input type="text" name="batchcode1" id="batchcode1" maxLength="8" onChange={onFielsValueChange} className="form-control batchcode" placeholder="Batch No (1st Product)" />
                  </div>
                </div>
                <div className="row " style={{ marginTop: "10px" }}>
                  <div className="col-lg-12">
                    <input type="text" name="batchcode2" id="batchcode2" maxLength="8" className="form-control batchcode" placeholder="Batch No (2nd Product) (optional)" />
                  </div>
                </div>
                <div className="row " style={{ marginTop: "10px" }}>
                  <div className="col-lg-12">
                    <input type="text" name="batchcode3" id="batchcode3" maxLength="8" className="form-control batchcode" placeholder="Batch No (3rd Product) (optional)" />
                  </div>
                </div>
                <div className="row " style={{ marginTop: "10px" }}>
                  <div className="col-lg-12">
                    <input type="text" name="batchcode4" id="batchcode4" className="form-control batchcode" maxLength="8" placeholder="Batch No (4th Product) (optional)" />
                  </div>
                </div>
                <div className="row " style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <div className="col-lg-12">
                    <input type="text" name="batchcode5" id="batchcode5" className="form-control batchcode" maxLength="8" placeholder="Batch No (5th Product) (optional)" />
                  </div>
                </div>

              </fieldset>
              <div className="row " style={{ marginTop: "18px", marginBottom: "10px" }}>
                <div className="col-lg-12">
                  <button type="button" style={{ backgroundColor: "#5C9D04", color: "white" }} onClick={reedemPromoCode} className="btn"  >Submit</button>

                </div>
              </div>
            </>}
          </>}


        </form>
      </>}
      {expire && <div><p style={{ color: "red", fontSize: "40px" }}>Offer Expired</p></div>}

    </>

  )

}

export default OldOutlet;

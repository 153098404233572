import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

const NewOutlet = () => {
  const [fieldsvalue, setfieldsvalue] = useState({
    phoneNumber: "",
    otp: "",
    isUPI: false,
    shopName: "",
    ownerName: "",
    address: "",
    upiId: ""
  });

  const [show, setShow] = useState(true);
  const onFielsValueChange = (e) => {
    setfieldsvalue({
      ...fieldsvalue,
      [e.target.name]: e.target.value
    })
  }

  const getOtp = async () => {


    if ((fieldsvalue.ownerName && fieldsvalue.address && fieldsvalue.shopName) == '') {
      swal("Shop Name/Owner Name/Address can't be blank", "", "error");
    }
    else if (!/^[a-zA-Z ]*$/.test(fieldsvalue.ownerName)) {
      swal("Owner Name should be alphabets", "", "error");
    } else if (fieldsvalue.phoneNumber == '' || fieldsvalue.phoneNumber.length != 10) {
      swal("Please Enter Valid Phone No", "", "error");
    }
    else {
      swal({
        title: "Please wait.....",
        buttons: false,
      });
      let otpResponse = await fetch(`https://api.dailymoo.in/v2/promo/otp?action=send&phoneNumber=${fieldsvalue.phoneNumber}`);
      swal.close();
      if (otpResponse.status == 200) {

        document.getElementById('phoneNumber').readOnly = true;
        setShow(true);
      }else {
        swal("Something Went Wrong", "", "error");
      }
    }
  }
const registerOutlet = async () => { 

  if ((fieldsvalue.ownerName && fieldsvalue.address && fieldsvalue.shopName) == '') {
    swal("Shop Name/Owner Name/Address can't be blank", "", "error");
  }    else if (!/^[a-zA-Z ]*$/.test(fieldsvalue.ownerName)) {
    swal("Owner Name should be alphabets", "", "error");
  }else if (fieldsvalue.otp == '' || fieldsvalue.otp.length != 6) {
    swal("Please Enter Valid OTP", "", "error");
  } else if (!fieldsvalue.isUPI && (fieldsvalue.upiId == '' || fieldsvalue.upiId.length != 10 || !/^[0-9]+\.?[0-9]*$/.test(fieldsvalue.upiId))) {
    swal("Please Enter Valid Paytm No.", "", "error");

  }else if (fieldsvalue.isUPI && (fieldsvalue.upiId == '' || fieldsvalue.upiId.indexOf("@") < 0)) {
    swal("Please Enter Valid UPI Id", "", "error");
  }
  else {

    
    var isPaytm = true;

    if (fieldsvalue.isUPI) {
      isPaytm = false;
    }
    swal({
      title: "Please wait.....",
      buttons: false,
    });
    let outLetData = {
      "shopName": fieldsvalue.shopName,
      "ownerName": fieldsvalue.ownerName,
      "phoneNumber": fieldsvalue.phoneNumber,
      "address": fieldsvalue.address,
      "otp": fieldsvalue.otp,
      "upiId": fieldsvalue.upiId,
      "isPaytm": isPaytm
    };

          var reedemResponse = await fetch('ttps://api.dailymoo.in/v2/promo/otp', {
            method: 'POST',
            body: JSON.stringify(outLetData)
          });
          swal.close();
          if (reedemResponse.status == 200) {
             var responseReedeem = await reedemResponse.json();
             setfieldsvalue({ ...fieldsvalue, 
              phoneNumber: "",
              otp: "",
              isUPI: false,
              shopName: "",
              ownerName: "",
              address: "",
              upiId: ""
            })
            swal("Success", responseReedeem.message, "success");
          
          } else if (reedemResponse.status == 400) {
            var errorresponseReedeem = await reedemResponse.json();
            swal("Warning!", errorresponseReedeem.error, "error");
          } else {
            swal("Error!", "Something Went Wrong", "error");
          }
  }

}

  useEffect(() => {
    document.title = "New Outlet";

  }, []);
  return (
    <>
      <div className="row" style={{ backgroundColor: "#5C9D04" }}>
        <div className="col-lg-12 col-xs-12" style={{ marginTop: "15px" }}>
          <div className="col-lg-2 col-xs-2"></div>
          <div className="col-lg-7 col-xs-7"><p style={{ color: "white" }}><b>New Outlet Registation</b></p>
            <p style={{ color: "white" }}><b>(ନୂତନ ଆଉଟଲେଟ୍ ପଞ୍ଜୀକରଣ)</b></p>
          </div>
          <div className="col-lg-2.25 col-xs-2.25" style={{ float: "right" }}>
            <span style={{ float: "right" }}>
              <a href='tel:+91 7894409550'>
                <svg width="20px" height="20px" viewBox="0 0 24 24" style={{ fill: "#FFFFFF" }} xmlns="http://www.w3.org/2000/svg">
                  <path d="M6,17 L6,11 L4.5,11 C3.67157288,11 3,11.6715729 3,12.5 L3,15.5 C3,16.3284271 3.67157288,17 4.5,17 L6,17 Z M13.9146471,20 L16.0584816,20 C16.7041272,20 17.2773354,19.5868549 17.4815065,18.9743416 L17.7094306,18.2905694 C17.7432317,18.1891661 17.7850711,18.0921054 17.8340988,18 L17.5,18 C17.2238576,18 17,17.7761424 17,17.5 L17,10.5 C17,10.2238576 17.2238576,10 17.5,10 L18,10 L18,8.98439023 C18,5.67068173 15.3137085,2.98439023 12,2.98439023 C8.6862915,2.98439023 6,5.67068173 6,8.98439023 L6,10 L6.5,10 C6.77614237,10 7,10.2238576 7,10.5 L7,17.5 C7,17.7761424 6.77614237,18 6.5,18 L4.5,18 C3.11928813,18 2,16.8807119 2,15.5 L2,12.5 C2,11.1192881 3.11928813,10 4.5,10 L5,10 L5,8.98439023 C5,5.11839698 8.13400675,1.98439023 12,1.98439023 C15.8659932,1.98439023 19,5.11839698 19,8.98439023 L19,10 L19.5,10 C20.8807119,10 22,11.1192881 22,12.5 L22,15.5 C22,16.8807119 20.8807119,18 19.5,18 C19.1180249,18 18.778905,18.2444238 18.6581139,18.6067972 L18.4301898,19.2905694 C18.0899047,20.3114248 17.1345576,21 16.0584816,21 L13.9146471,21 C13.7087289,21.5825962 13.1531094,22 12.5,22 L11.5,22 C10.6715729,22 10,21.3284271 10,20.5 C10,19.6715729 10.6715729,19 11.5,19 L12.5,19 C13.1531094,19 13.7087289,19.4174038 13.9146471,20 L13.9146471,20 Z M18,11 L18,17 L19.5,17 C20.3284271,17 21,16.3284271 21,15.5 L21,12.5 C21,11.6715729 20.3284271,11 19.5,11 L18,11 Z M11,20.5 C11,20.7761424 11.2238576,21 11.5,21 L12.5,21 C12.7761424,21 13,20.7761424 13,20.5 C13,20.2238576 12.7761424,20 12.5,20 L11.5,20 C11.2238576,20 11,20.2238576 11,20.5 Z" />
                </svg>
              </a>
              <Link to="/">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 50 50" style={{ fill: "#FFFFFF", marginLeft: "20px" }}>
                  <path d="M 25 1.0507812 C 24.7825 1.0507812 24.565859 1.1197656 24.380859 1.2597656 L 1.3808594 19.210938 C 0.95085938 19.550938 0.8709375 20.179141 1.2109375 20.619141 C 1.5509375 21.049141 2.1791406 21.129062 2.6191406 20.789062 L 4 19.710938 L 4 46 C 4 46.55 4.45 47 5 47 L 19 47 L 19 29 L 31 29 L 31 47 L 45 47 C 45.55 47 46 46.55 46 46 L 46 19.710938 L 47.380859 20.789062 C 47.570859 20.929063 47.78 21 48 21 C 48.3 21 48.589063 20.869141 48.789062 20.619141 C 49.129063 20.179141 49.049141 19.550938 48.619141 19.210938 L 25.619141 1.2597656 C 25.434141 1.1197656 25.2175 1.0507812 25 1.0507812 z M 35 5 L 35 6.0507812 L 41 10.730469 L 41 5 L 35 5 z"></path>
                </svg></Link>
            </span>
          </div>
        </div>
      </div>
      <div className="row" style={{ marginTop: "10px" }}>
        <div className="col-lg-12">
          <input type="text" name="shopName" id="shopName" className="form-control" onChange={onFielsValueChange} placeholder="Enter Shop Name (ଦୋକାନର ନାମ)" />

        </div>
      </div>
      <div className="row" style={{ marginTop: "10px" }}>
        <div className="col-lg-12">

          <input type="text" name="ownerName" id="ownerName" className="form-control" onChange={onFielsValueChange} placeholder="Enter Owner Name (ମାଲିକ ନାମ)" />
        </div>
      </div>
      <div className="row" style={{ marginTop: "10px" }}>
        <div className="col-lg-12">

          <input type="text" name="address" id="address" className="form-control" onChange={onFielsValueChange} placeholder="Enter Your Shop Address (ଦୋକାନ ଠିକଣା)" />
        </div>
      </div>
      <div className="row" style={{ marginTop: "10px" }}>
        <div className="col-lg-12">

          <input type="number" name="phoneNumber" id="phoneNumber" autoComplete="off" value={fieldsvalue.phoneNumber} className="form-control" onChange={(event) => {
            if (event.target.value.length === 11) return false;
            setfieldsvalue({ ...fieldsvalue, phoneNumber: event.target.value })

          }} placeholder="Enter Your Mobile No. (ମୋବାଇଲ୍ ନମ୍ବର)" />
        </div>
      </div>
    {!show && <>  <div className="row " style={{ marginTop: "18px" }}>
        <div className="col-lg-12">
          <button type="button" style={{ backgroundColor: "#5C9D04", color: "white" }} className="btn" onClick={getOtp} >Send OTP</button>

        </div>
      </div>
      </>}
      {show && <> <div className="row" style={{ marginTop: "10px" }}>
        <div className="col-lg-12">
          <input type="number" name="otp" id="otp" className="form-control" autoComplete="off" value={fieldsvalue.otp} onChange={(e) => {
            if (e.target.value.length === 7) return false;
            setfieldsvalue({ ...fieldsvalue, otp: e.target.value })
          }} placeholder="EnterThe OTP (ଓଟିପି ନମ୍ବର)" />

        </div>
      </div>
      <div className="row" style={{ marginTop: "10px" }}>
        <div className="col-lg-12">
          <div className="col-lg-4">
            <input type="radio" name="payment_method" className="radio_btn" id="payment_method" value="Paytm" defaultChecked onChange={(e) => {

              setfieldsvalue({ ...fieldsvalue, isUPI: false })
            }} />&nbsp;&nbsp;&nbsp;Paytm <p style={{ fontSize: "12px", color: "#5C9D04" }}>(Recommended)</p>
          </div>
          <div className="col-lg-2"><input type="radio" className="radio_btn" name="payment_method" id="payment_method" value="UPI" onChange={(e) => {

            setfieldsvalue({ ...fieldsvalue, isUPI: true })
          }} />&nbsp;&nbsp;&nbsp;UPI</div>

        </div>
      </div>
      <div className="row" style={{ marginTop: "10px" }}>
        <div className="col-lg-12">
          <input type="text" name="upiId" id="upiId" autoComplete="off" onChange={onFielsValueChange} className="form-control" placeholder={fieldsvalue.isUPI == true ? "Enter Your UPI Id" : "Enter Your Paytm Number"} />


        </div>
      </div>
      <div className="row " style={{ marginTop: "18px", marginBottom: "10px" }}>
        <div className="col-lg-12">
          <button type="button" style={{ backgroundColor: "#5C9D04", color: "white" }} className="btn" onClick={registerOutlet} >Submit</button>

        </div>
      </div>
      </> }
    </>

  )

}
export default NewOutlet;
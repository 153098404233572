import react from "react";
import splash2 from "../Images/splash2.png";
import allProductImage from "../Images/product_all_new.png";
import { useEffect, useState } from "react";
import howToUse from "../Images/howToUse.jpg";
import { Modal } from 'react-bootstrap';
import swal from 'sweetalert';


const MyHome = () => {
  const [maximumLength, setmaximumLength] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //console.log(show);

  let outletId = react.createRef();

  const redirectToOldOutlet = async () => {
    if (outletId.current.value == "") {

      swal("Please Enter Outlet Id", "", "error");

    } else {
      let res = await fetch(`https://api.dailymoo.in/v2/promo/promo?action=verify_retailer&outletId=${outletId.current.value}`);
      if (res.status == 200) {
      window.location.href = `/Old?outletid=${outletId.current.value}`; }
      else { 
        swal("Invalid Outlet Id", "", "error");
        outletId.current.value ='';
      }
    }
  }

  const redirectToNewOutlet = () => {
    window.location.href=`/New`;
  }

  return (

    <div>
      <div className="col-lg-12">
        <div className="col-lg-4 ">
        </div>
        <div className="col-lg-4 ">
          <img src={splash2} style={{ width: "200px" }} /><br></br>
          <img src={allProductImage} style={{ marginTop: "50px" }} />
        </div>
        <div className="col-lg-4 ">
        </div>

      </div>
      <div className="col-lg-12" style={{ marginTop: "20px" }}>
        <div className="col-lg-4 ">
        </div>
        <div className="col-lg-4 ">
          <h3 style={{ color: "#5C9D04" }}>MilkyMoo Offer</h3>
          <p style={{ color: "#5C9D04" }}>Get flat 10 &#x20B9; in your paytm wallet / UPI</p>
          <p style={{ color: "#5C9D04" }}>Minimum purchase of 50 &#x20B9;</p>
        </div>
        <div className="col-lg-4 ">
        </div>

      </div>
      <div className="col-lg-12" style={{ marginTop: "50px" }}>
        <div className="col-lg-4 ">
        </div>
        <div className="col-lg-4 ">
          <input type="number" ref={outletId} name="outletid" id="outletid" value={maximumLength} className="form-control" placeholder="Enter Outlet ID" onChange={(event) => {
            if (event.target.value.length == 11) return false;
            setmaximumLength(event?.target.value);
          }
          } />
          <button type="button" className="btn" style={{ backgroundColor: "#5C9D04", marginTop: "20px" }} onClick={redirectToOldOutlet} ><b style={{ color: "white" }}>Redeem Offer</b></button>
        </div>
        <div className="col-lg-4 ">
        </div>

      </div>
      <div className="col-lg-12" style={{ marginTop: "15px" }}>
        <div className="col-lg-4 ">
        </div>
        <div className="col-lg-4 ">
          <p ><b style={{ color: "#5C9D04" }}><u onClick={handleShow}>How To Use</u></b> </p>
          <p ><b style={{ color: "#5C9D04" }} onClick={redirectToNewOutlet}>New Outlet Registration (ନୂଆ ଦୋକାନ ପଞ୍ଜୀକରଣ)</b> </p>

        </div>
        <div className="col-lg-4 ">
        </div>

      </div>
     

      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body><img src={howToUse} /></Modal.Body>

      </Modal>

    </div>

  )
}

export default MyHome;
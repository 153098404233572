import React from 'react';
import App from './Components/App';
import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';



createRoot(
  document.getElementById('root')
  ).render(
  <App />
  );




